/**
 * STUDENT
 */
export const STUDENT_SUBS = 'STUDENT_SUBS';
export const STUDENT_IS_PART = 'STUDENT_IS_PART';
export const STUDENT_NOT_PART = 'STUDENT_NOT_PART';
export const STUDENT_WAS_PART = 'STUDENT_WAS_PART';
export const STUDENT_SUBED = 'STUDENT_SUBED';
export const UPDATE_STUDENT_LABS_PENDING = 'UPDATE_STUDENT_LABS_PENDING';
export const STUDENT_LABS_FETCHED = 'STUDENT_LABS_FETCHED';
export const ERROR_IN_STUDENT_AUTH = 'ERROR_IN_STUDENT_AUTH';
export const ERROR_IN_STUDENT_LABS_UPDATE = 'ERROR_IN_STUDENT_LABS_UPDATE';
export const ERROR_IN_STUDENT_LABS_FETCH = 'ERROR_IN_STUDENT_LABS_FETCH';
export const FETCH_STUDENT_PENDING = 'FETCH_STUDENT_PENDING';
export const STUDENT_LOGOUT = 'STUDENT_LOGOUT';

/**
 * ASSEMBLY
 */
// Fetch assembly
export const FETCH_ASSEMBLY_PENDING = 'FETCH_ASSEMBLY_PENDING';
export const ASSEMBLY_FETCHED = 'ASSEMBLY_FETCHED';
export const ERROR_IN_ASSEMBLY_FETCH = 'ERROR_IN_ASSEMBLY_FETCH';
// Backup assembly
export const REQUEST_ASSEMBLY_BACKUP = 'REQUEST_ASSEMBLY_BACKUP';
export const ASSEMBLY_BACKUP_COMPLETED = 'ASSEMBLY_BACKUP_COMPLETED';
export const ERROR_IN_ASSEMBLY_BACKUP = 'ERROR_IN_ASSEMBLY_BACKUP';
// Load assembly
export const REQUEST_ASSEMBLY_LOAD = 'REQUEST_ASSEMBLY_LOAD';
export const ASSEMBLY_LOAD_COMPLETED = 'ASSEMBLY_LOAD_COMPLETED';
export const ERROR_IN_ASSEMBLY_LOAD = 'ERROR_IN_ASSEMBLY_LOAD';
// Delete assembly
export const DELETE_ASSEMBLY_PENDING = 'DELETE_ASSEMBLY_PENDING';
export const ASSEMBLY_DELETED = 'ASSEMBLY_DELETED';
export const ERROR_IN_ASSEMBLY_DELETE = 'ERROR_IN_ASSEMBLY_DELETE';
// Fetch info
export const FETCH_INFO_PENDING = 'FETCH_INFO_PENDING';
export const ASSEMBLY_SUBS_CLOSE = 'ASSEMBLY_SUBS_CLOSE';
export const ASSEMBLY_SUBS_OPEN = 'ASSEMBLY_SUBS_OPEN';
export const ASSEMBLY_NOT_AVABILE = 'ASSEMBLY_NOT_AVABILE';
export const ERROR_IN_INFO_FETCH = 'ERROR_IN_INFO_FETCH';
// Create info
export const CREATE_INFO_PENDING = 'CREATE_INFO_PENDING';
export const INFO_CREATED = 'INFO_CREATED';
export const ERROR_IN_INFO_CREATE = 'ERROR_IN_INFO_CREATE';
// Update info
export const UPDATE_INFO_PENDING = 'UPDATE_INFO_PENDING';
export const INFO_UPDATED = 'INFO_UPDATED';
export const ERROR_IN_INFO_UPDATE = 'ERROR_IN_INFO_UPDATE';
// Fetch laboratories
export const FETCH_LABS_PENDING = 'FETCH_LABS_PENDING';
export const LABS_FETCHED = 'LABS_FETCHED';
export const ERROR_IN_LABS_FETCH = 'ERROR_IN_LABS_FETCH';
// Create laboratory
export const CREATE_LAB_PENDING = 'CREATE_LAB_PENDING';
export const LAB_CREATED = 'LAB_CREATED';
export const ERROR_IN_LAB_CREATE = 'ERROR_IN_LAB_CREATE';
// Update laboratory
export const UPDATE_LAB_PENDING = 'UPDATE_LAB_PENDING';
export const LAB_UPDATED = 'LAB_UPDATED';
export const ERROR_IN_LAB_UPDATE = 'ERROR_IN_LAB_UPDATE';
// Delete laboratory
export const DELETE_LAB_PENDING = 'DELETE_LAB_PENDING';
export const LAB_DELETED = 'LAB_DELETED';
export const ERROR_IN_LAB_DELETE = 'ERROR_IN_LAB_DELETE';
// Fetch students
export const FETCH_STUDENTS_PENDING = 'FETCH_STUDENTS_PENDING';
export const STUDENTS_FETCHED = 'STUDENTS_FETCHED';
export const ERROR_IN_STUDENTS_FETCH = 'ERROR_IN_STUDENTS_FETCH';
// Generate pdf
export const REQUEST_ASSEMBLY_PDF = 'REQUEST_ASSEMBLY_PDF';
export const ASSEMBLY_PDF_COMPLETED = 'ASSEMBLY_PDF_COMPLETED';
export const ERROR_IN_ASSEMBLY_PDF = 'ERROR_IN_ASSEMBLY_PDF';

/**
 * ADMIN
 */
export const AUTH_ADMIN_PENDING = 'AUTH_ADMIN_PENDING';
export const ADMIN_AUTHED = 'ADMIN_AUTHED';
export const ERROR_IN_ADMIN_AUTH = 'ERROR_IN_ADMIN_AUTH';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const UPDATE_ADMIN_TOKEN = 'UPDATE_ADMIN_TOKEN';
export const AUTH_SUDOER_PENDING = 'AUTH_SUDOER_PENDING';
export const SUDOER_AUTHED = 'SUDOER_AUTHED';
export const ERROR_IN_SUDOER_AUTH = 'ERROR_IN_SUDOER_AUTH';

/**
 * PREFERENCE
 */
export const SWITCH_THEME = 'SWITCH_THEME';
